import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import { Heading, Text, Link, Flex, Box, Avatar } from "@modulz/radix"
import SEO from "../components/seo"

const style = {
  block: { display: 'block' },
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query CVQuery {
      allFile(filter: { sourceInstanceName: { eq: "docs" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
`)

  return (<Layout>
    <SEO title="Dan Nisbet - UX Engineer" />
    <Flex my={[3, null, '10vh']}>
      <Box mx={[2, 4, 6]}>

        <Avatar alt="Dan Nisbet" height={9} width={9} src="https://avatars0.githubusercontent.com/u/478837" />

        <Box pt={['3vh', null, '5vh']} pb={'8vh'} px={[0, 0, 9]}>

          <Text mt={2} size={4} style={style.block} as="h1" textColor='gray800'>Dan Nisbet, Crafted Digital Experiences</Text>
          <Text mt={3} size={8} style={style.block} as="p">I have always focused on the interface between people and technology; delighting in making things you can play and interact with. A digital career spanning over fifteen years, from Art Director to UX Engineer, the one thing that has always fascinated me is building and designing systems and interactive experiences.
          </Text>
          <Text mt={4} mb={8} size={7} style={style.block} as="p">
            <Link href={data.allFile.edges[0].node.publicURL} title='Download CV'>View my CV</Link>
          </Text>

          <Heading size={11} mt={5} mb={3} fontWeight={500} style={style.block}>Freelance</Heading>

          <Text my={2} style={style.block}><Link href='https://www.youtube.com/watch?v=StstpSG2_CA'>Wood Mackenzie</Link></Text>
          <Text my={2} style={style.block}><Link href='https://citygate.co.uk/'>Citygate</Link></Text>
          <Text my={2} style={style.block} textColor='gray600'>LBX</Text>
          <Text my={2} style={style.block} textColor='gray600'>New Commonwealth</Text>
          <Text my={2} style={style.block}>BikeParkPRO (<Link href='https://www.bikeparkpro.com/'>Web</Link>) (<Link href='https://itunes.apple.com/us/app/bikeparkpro/id1000675979?ls=1&mt=8'>iOS</Link>)</Text>
          <Text my={2} style={style.block} textColor='gray600'><Link href='https://www.draytonpartners.com/'>Drayton Partners</Link></Text>
          <Text my={2} style={style.block}><Link href='https://littlerocketrecords.co.uk/'>Little Rocket Records</Link></Text>

          <Heading size={11} mt={5} mb={3} fontWeight={500} style={style.block}>TH_NK</Heading>
          <Text my={2} style={style.block} textColor='gray600'>Channel 4 | Utopia</Text>
          <Text my={2} style={style.block} textColor='gray600'>BlackBerry | Live & Lost</Text>
          <Text my={2} style={style.block} textColor='gray600'>Sony | Playstaion Vita</Text>
          <Text my={2} style={style.block} textColor='gray600'>Warner Bros</Text>
          <Text my={2} style={style.block} textColor='gray600'>Drinkaware</Text>

          <Heading size={11} mt={5} mb={3} fontWeight={500} style={style.block}>The Roundhouse</Heading>
          <Text my={2} style={style.block} textColor='gray600'>Entrepreneurs' Forum</Text>
          <Text my={2} style={style.block} textColor='gray600'>Silverlining Furniture</Text>
          <Text my={2} style={style.block} textColor='gray600'>Chromazone</Text>
          <Text my={2} style={style.block} textColor='gray600'>Seven Stories</Text>


          <Text my={2} mt={8} style={style.block}><Link href='https://www.instagram.com/_s0e_/'>Instagram</Link></Text>
          <Text my={2} style={style.block}><Link href='https://twitter.com/thedistricts'>Twitter</Link></Text>
          <Text my={2} style={style.block}><Link href='https://github.com/thedistricts'>Github</Link></Text>

        </Box>


        <Text size={1} textColor='gray600' fontFamily='mono'><em>built with <Link href='https://github.com/modulz/radix/tree/master/packages/radix'>Radix</Link></em></Text>

      </Box>
    </Flex>
  </Layout>
  )
}

export default IndexPage
